import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";
import Twitter from "../../images/footTwitter.png";
import Opensea from "../../images/footOpeansea.png";
import Discord from "../../images/footDiscord.png";
import EighteenPlus from "../../images/eighteenPlus.png";
import Logo from "../../images/footerlogo1.png";
import Arrow from "../../images/footerArrow.png";
import { TfiTwitter } from "react-icons/tfi";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import HiOutlineArrowLongRight from "react-icons/hi";
import { SlSocialInstagram } from "react-icons/sl";
import { TbBrandDiscord } from "react-icons/tb";
import { SiTelegram, SiDiscord } from "react-icons/si";
import { BsTwitter } from "react-icons/bs";
import Opensea1 from "../../images/OpenseaNew.png";
const Footers = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };
  const menu = [
    // {
    //   name: "About Us",
    //   url: "",
    //   target: "_self",
    // },
    {
      name: "Security and Privacy Policy",
      url: "/Privacypolicy",
      target: "_self",
    },
    {
      name: "Terms and Conditions",
      url: "/Terms",
      target: "_self",
    },
    {
      name: "Game Guide",
      url: "https://cyberdogzdaily.com",
      target: "_blank",
    },
    {
      name: "Community",
      url: "https://discord.gg/CYBERDOGZ",
      target: "_blank",
    },
  ];
  return (
    <footer className={styles.footer}>
      {/* <div className={cn("container", styles.container)}> */}
      {/* <div className={styles.buttons}>
      <a href="https://twitter.com/gocyberdogz" target="_blank">
        <img src={Twitter} className={styles.twitter} />
        </a>
        <a href="https://discord.gg/CYBERDOGZ" target="_blank">
        <img src={Discord} className={styles.discord} />
        </a>
        <a href="https://opensea.io/collection/officialcyberdogz" target="_blank">
        <img src={Opensea} className={styles.opensea} />
        </a>
      </div>
      <div className={styles.foot}>
        <div className={styles.note}>CYBERDOGZ</div>
        <img src={EighteenPlus} className={styles.eighteenPlus} />
        <div className={styles.copyright}>
          Copyright 2022 CYBERDOGZ. <Link to={"/Terms"}> Terms of Use.</Link> <Link to="/Privacypolicy">Privacy Policy.</Link><Link to={"/Disclaimer"}> Disclaimer.</Link>
          Smart Contract.
        </div>
      </div> */}
      <div className={styles.flexLink}>
        {menu.map((data, index) => (
          <a href={data.url} key={index} target={data.target}>
            <div className={styles.menu}>{data.name}</div>
          </a>
        ))}
      </div>
      <div className={styles.flexImg}>
        {/* <img src={Logo} /> */}
        <Link to="/">
          <div className={styles.text}>CYBERDOGZ</div>
        </Link>
        <div className={styles.content}>
          <span className={styles.subhead}>Please note:</span>{"  "}CYBERDOGZ is in
          beta and a multi-player game. CYBERDOGZ makes no warranty expressly or
          implied about our products and services. Please be careful with your
          funds, CYBERDOGZ uses blockchain technology which means we are unable
          to process refunds. CYBERDOGZ is not a financial asset, nor an
          investment and is used strictly for entertainment purposes only with
          no warranties.
        </div>
      </div>
      <div className={styles.flexCopyright}>
        <span>TM + Copyright 2022 CyberDogz.io. All rights reserved.</span>
        <div className={styles.community}>
          <div>Join our community </div>
          <img src={Arrow} />
          <div className={styles.icons}>
            <a
              href="https://twitter.com/gocyberdogz"
              target="_blank"
              rel="noreferrer"
            >
              <TfiTwitter className={styles.Twitter} />
            </a>
            <a
              href="https://www.instagram.com/cyberdogz.io/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
              rel="noreferrer"
            >
              <SlSocialInstagram className={styles.insta} />
            </a>
            <a
              href="https://discord.gg/CYBERDOGZ"
              target="_blank"
              rel="noreferrer"
            >
              <TbBrandDiscord className={styles.discords} />
            </a>
            <a
              href="https://discord.gg/CYBERDOGZ"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Opensea1} className={styles.opensea1} />
            </a>
          </div>
        </div>
      </div>
      {/* </div> */}
    </footer>
  );
};

export default Footers;
