import React, { useEffect, useState } from "react";
import Styles from "../Challanges.module.sass";
import { Row, Col, Empty } from "antd";
import moment from "moment";
import Loader from "../../../../../components/Loader";
import { minifyAddress } from "../../../../../utils/helpers";
const CurrentChallenge = ({ leaderboard, loading }) => {
  return (
    <>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={4}>
              <div className={Styles.seller}>Rank</div>
            </Col>
            <Col span={12}>
              <div className={Styles.seller}>Name</div>
            </Col>
            <Col span={8}>
              <div className={Styles.seller}>Scores</div>
            </Col>
            {/* <Col span={8}>
              <div className={Styles.seller}>Time</div>
            </Col> */}
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
        {loading ? (
          <Loader className={Styles.loader} />
        ) : leaderboard.length ? (
          <>
            {leaderboard.map((data, index) => {
              return (
                <div className={Styles.rowdata} key={index}>
                  <Row>
                    <Col span={4}>
                      <div className={Styles.pricefield}>{data.rank}</div>
                    </Col>

                    {/* <Col span={8}>
                      <div className={Styles.status}>{data.playerId}</div>
                    </Col> */}
                    <Col span={12}>
                      <div className={Styles.status}>{data.playerName}</div>
                    </Col>
                    <Col span={8}>
                      <div className={Styles.pricefield}>{data.totalScore}</div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
      </div>
    </>
  );
};
export default CurrentChallenge;
