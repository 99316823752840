import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Challanges.module.sass";
import CurrentChallenge from "./CurrentChallenge";

const navLinks = ["Live", "All time"];

const Challenges = ({
  currentChallenge,
  getLeaderboard,
  isLeaderboardLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(()=>{
    getLeaderboard(activeIndex?false:true)
  },[activeIndex])

  return (
    <>
      <div className={cn("container", styles.container)}>
        <p className={styles.title}>
          <span className={styles.heading}>LEADERBOARD </span>
        </p>

        <div className={styles.nav}>
          {navLinks.map((x, index) => (
            <button
              // className={cn(styles.link, {
              //   [styles.active]: index === activeIndex,
              // })}
              className={
                index == activeIndex ? "leader-active" : "leader"
              }
              key={index}
              onClick={() => setActiveIndex(index)}
            >
              <div className={
                index == activeIndex ? "" : "leader-text"
              }>{x}</div>
            </button>
          ))}
        </div>

        <div className={styles.group}>
          <div className={styles.item}>
                <CurrentChallenge
                  getLeaderboard={getLeaderboard}
                  currentChallenge={currentChallenge}
                  isLeaderboardLoading={isLeaderboardLoading}
                />
          </div>
        </div>
      </div>
    </>
  );
};

export default Challenges;
