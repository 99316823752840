import React, { useEffect, useRef, useState } from "react";
import Styles from "./SpaceBattle.module.sass";
import {
  SignatureLogin,
  SpaceCrateLeaderBoard,
} from "../../services/ApiServices";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { useWeb3React } from "@web3-react/core";
import Challenges from "./Challenges";
import AppIcon from "../../images/app-icon.png";
import Metamask from "../../images/Metamask.png";
import Arrow from "../../images/LoginRound.png";
import Arrow1 from "../../images/Enter.png";
import Bg from "../../images/shooterBg.png";
import { SIGNATURE_MESSAGE_KEY } from "../../Config/config";
import { ethers } from "ethers";
import Web3 from "web3";
import BlackModal from "../../components/BlackModal";
import TextInput from "../../components/TransparentTextInput";
import { Tooltip } from "antd";
import { FaCopy } from "react-icons/fa";
import PrimaryButton from "../../components/PrimaryButton";

const SpaceBattle = (props) => {
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const [currentChallenge, setCurrentChallenge] = useState([]);
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [isCodeModal, setIsCodeModal] = useState(false);
  const [code, setCode] = useState();

  const downloads = [
    {
      name: "Sign in To Metamask",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo justo, molestie ornare sodales ac, malesuada.",
      icon: Metamask,
    },
    {
      name: "Get Login Code",
      text: "Here",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo justo, molestie ornare sodales ac, malesuada.",
      icon: Arrow,
      url: "/profile/" + account,
      button: "Login",
    },

    {
      name: "Use Your Code to Login",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo justo, molestie ornare sodales ac, malesuada.",
      icon: Arrow1,
    },
  ];

  useEffect(() => {
    getLeaderboard(true);
  }, []);
  const getLeaderboard = async (live) => {
    setIsLeaderboardLoading(true);
    let result;
    try {
      result = await SpaceCrateLeaderBoard(live);
    } catch (e) {
      console.log(e);
      setIsLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setIsLeaderboardLoading(false);
        setCurrentChallenge(result.result);
      }
    } catch (e) {
      setIsLeaderboardLoading(false);
      console.log(e);
    }
  };
  const getSignature = async () => {
    if (!window.ethereum)
      return enqueueSnackbar("Metamask is not found.", { variant: "error" });

    setSignatureLoading(true);
    var message = SIGNATURE_MESSAGE_KEY;
    var hash = Web3.utils.sha3(message);
    let signature;

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      signature = await signer.signMessage(hash);
    } catch (e) {
      let message = e.message.split(":");
      setSignatureLoading(false);
      console.log(e);
      return enqueueSnackbar(
        message[0] === "MetaMask Message Signature" ? message[1] : message,
        { variant: "error" }
      );
    }
    // console.log("signature", signature);
    signatureLogin(signature);
  };
  const signatureLogin = async (signature) => {
    setSignatureLoading(true);
    let result;
    try {
      result = await SignatureLogin(signature);
    } catch (e) {
      console.log(e);
      setSignatureLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
    try { 
      if (result.success) {
        setIsCodeModal(true);
        setSignatureLoading(false);
        setCode(result.code);
        enqueueSnackbar(result.message, { variant: "info" });
      }
    } catch (e) {
      setSignatureLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e);
    }
  };
  return (
    <div className={cn("container")}>
      <div>
        <div className={Styles.body}>
          <img src={Bg} className={Styles.bodyMask} />
          {/* <img src={AppIcon} className={Styles.appIcon} /> */}
          <div className={Styles.contents}>
            <div className={Styles.head}>CYBERDOGZ SPACESHIP SHOOTER</div>
            <div className={Styles.blueshade}>
              Save your galaxy, conquer planet Andromeda
            </div>
            <button
              className={Styles.buyNow}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.cyberdogz.battleship",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              Download Now
            </button>
            {/* <div className={Styles.spacemain}>
              <div className={Styles.spaceText}>
                
                <li>
                {" "}
                Download the app from{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.cyberdogz.battleship"
                  target="blank"
                >
                  here
                </a>
              </li>
              <li>Sign In Metamask</li>
              <li>
                Get login code{" "}
                {account ? (
                  <Link to={"/profile/" + account}> here</Link>
                ) : (
                  <Link
                    to={"/spaceshooter"}
                    onClick={() => {
                      enqueueSnackbar("Connet your wallet.", {
                        variant: "error",
                      });
                    }}
                  >
                    {" "}
                    here
                  </Link>
                )}
              </li>
              <li>Use your address and code to login</li>
              </div>
            </div> */}
          </div>
        </div>
        <div className={Styles.background}>
          <div className={Styles.downloadBody}>
            <div className={Styles.heads}>
              DOWNLOAD GAME FROM PLAYSTORE{" "}
              <a
                style={{ color: "#EDDC65" }}
                href="https://play.google.com/store/apps/details?id=com.cyberdogz.battleship"
                target="blank"
              >
                HERE
              </a>
              !
            </div>
            <div className={Styles.text}>
              Follow the rest procedure when you’re done downloading to finish
              up the process.
            </div>
            <div className={Styles.downloadFlex}>
              {downloads.map((x, index) => (
                <div key={index} className={Styles.bg}>
                  <img src={x.icon} />
                  <h2 className={Styles.margin}>
                    {x.name} {""}
                    {/* <span style={{ color: "#EDDC65" }}>{x.text}</span> */}
                    {x.text && (
                      <>
                        {account ? (
                          <button
                            onClick={() => {
                              getSignature();
                            }}
                            style={{ color: "#EDDC65" }}
                          >
                            {x.text}{" "}
                          </button>
                        ) : (
                          <Link
                            style={{ color: "#EDDC65" }}
                            to={"/spaceshooter"}
                            onClick={() => {
                              enqueueSnackbar("Connet your wallet.", {
                                variant: "error",
                              });
                            }}
                          >
                            {" "}
                            {x.text}
                          </Link>
                        )}
                      </>
                    )}
                  </h2>
                  {/* <p>{x.content}</p> */}
                  {/* {x.button && (
                    <PrimaryButton
                      className={Styles.loginBtn}
                      label={x.button}
                      loading={signatureLoading}
                      loadingtext="Verifying"
                      onClick={() => {
                        if (account) {
                          getSignature();
                        } else {
                          enqueueSnackbar("Connet your wallet.", {
                            variant: "error",
                          });
                        }
                      }}
                    />
                  )} */}
                </div>
              ))}
            </div>
          </div>
          <Challenges
            getLeaderboard={getLeaderboard}
            currentChallenge={currentChallenge}
            isLeaderboardLoading={isLeaderboardLoading}
          />
        </div>
      </div>
      <BlackModal
        visible={isCodeModal}
        outerClassName={Styles.codeModal}
        onClose={() => setIsCodeModal(false)}
      >
        <div className={Styles.codeModalBody}>
          <div className={Styles.modalTitle}>Login Code</div>
          <div className={Styles.content}>
            <TextInput value={code} />
            <button
              className={Styles.copycode}
              onClick={() => {
                navigator.clipboard.writeText(code);
              }}
            >
              <Tooltip title="Copied!" color="#2F2F2F" trigger="click">
                <FaCopy className={Styles.copyIcon} />
              </Tooltip>
            </button>
          </div>
          <div className={Styles.note}>
            <span className={Styles.noteHead}>Note: </span>
            Don't share the code to others.
          </div>
        </div>
      </BlackModal>
    </div>
  );
};
export default SpaceBattle;
