import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import { GetJson } from "../../services/ApiServices";
import Ethereum from "../../images/Ethereum.png";
import Polygon from "../../images/polygon.png";
import moment from "moment";
import { GoArrowUp } from "react-icons/go";
import { IoCalendar } from "react-icons/io5";
import Male from "../../images/male.png";
import Female from "../../images/female.png";
import { Tooltip, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "../CountDown";
import { getLandContract, getCapsuleContract } from "../../redux";
import NumberFormat from "react-number-format";
import BonezImg from "../../images/bonez.png";
const Card = ({ className, item }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [lot, setLot] = useState({});
  const [currentPrice, setCurrentPrice] = useState("");
  const [currentTier, setCurrentTier] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");

  const price = [
    { quantity: "1-5", price: "0.01" },
    { quantity: "6-10", price: "0.25" },
    { quantity: "11-15", price: "0.50" },
    { quantity: "16-20", price: "0.75" },
    { quantity: "21-25", price: "1.00" },
  ];
  const capsule = [
    { quantity: "1-50", price: "0.1" },
    { quantity: "51-99", price: "0.25" },
    { quantity: "100-199", price: "0.50" },
    { quantity: "200-399", price: "0.75" },
    { quantity: "400-1000", price: "1.00" },
  ];
  const land = [
    { quantity: "1-100,000", price: ".00002" },
    { quantity: "100,001-999,9999", price: ".0001" },
    { quantity: "1,000,000-2,500,000", price: ".0005" },
    { quantity: "2,500,001-5,000,000", price: ".0007" },
    { quantity: "5,000,001-7,500,000", price: ".0008" },
    { quantity: "7,500,001-8,500,000", price: ".001" },
    { quantity: "8,500,001-9,500,000", price: ".002" },
    { quantity: "9,500,001-10,000,000", price: ".003" },
  ];
  useEffect(() => {
    getUSDCurrency();
  }, [state.Usd]);
  const getUSDCurrency = () => {
    if (state.Usd.USD.length) {
      let usd = state.Usd.USD.find((element) => {
        return element.chainName == item.currency;
      });
      if (usd) setValue(usd.price);
    }
  };
  const getLot = async () => {
    let result;
    try {
      result = await GetJson(item.path);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setLot(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(()=>{
  //   getLot()
  // },[])

  // useEffect(() => {
  //   getTimeDifference();
  //   setInterval(() => getTimeDifference(), 1000);
  // }, [item.createdAt]);
  const contract = () => {
    if (
      Object.is(
        state.Contract.landContract.contractAddress,
        item.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.landContract.currentPrice);
      setCurrentTier(state.Contract.landContract.currentTier);
      setTotalSupply(state.Contract.landContract.totalSupply);
      setCurrentBalance(state.Contract.landContract.currentBalance);
    } else if (
      Object.is(
        state.Contract.capsuleContract.contractAddress,
        item.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.capsuleContract.currentPrice);
      setCurrentTier(state.Contract.capsuleContract.currentTier);
      setTotalSupply(state.Contract.capsuleContract.totalSupply);
      setCurrentBalance(state.Contract.capsuleContract.currentBalance);
    } else if (
      Object.is(
        state.Contract.assetContract.contractAddress,
        item.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.assetContract.currentPrice);
      setCurrentTier(state.Contract.assetContract.currentTier);
      setTotalSupply(state.Contract.assetContract.totalSupply);
      setCurrentBalance(state.Contract.assetContract.currentBalance);
    }
  };

  useEffect(() => {
    contract();
  }, [
    item.contractAddress,
    state.Contract.landContract.contractAddress,
    state.Contract.capsuleContract.contractAddress,
    state.Contract.assetContract.contractAddress,
  ]);

  const getTimeDifference = () => {
    const dateNow = moment.utc();
    const expiryDate = moment.utc(item.auctionDate);
    var duration = moment.duration(expiryDate.diff(dateNow));
    // const time = Date.parse(new Date(date)) - Date.parse(new Date());
    // const countdays = Math.floor(time / (1000 * 60 * 60 * 24));
    // const counthours = Math.floor((time / (1000 * 60 * 60)) % 24);
    // const countminutes = Math.floor((time / 1000 / 60) % 60);
    // const countseconds = Math.floor((time / 1000) % 60);
    setDays(duration.days());
    setHours(duration.hours());
    setMinutes(duration.minutes());
    setSeconds(duration.seconds());
    return (
      <div>
        {days} {hours}:{minutes}:{seconds}
      </div>
    );
  };
  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };
  return (
    <div className={cn(styles.card, className)}>
      <Link className={styles.link} to={"/detail/" + item._id}>
        <div className={styles.previewBorder}>
        <div
          className={styles.preview}
          // style={{
          //           backgroundImage: `linear-gradient(rgba(25 76 107 / 33%), rgba(25 76 107 / 33%)),url(${item.previewImage})`,
          //           backgroundPosition:"center",
          //           backgroundRepeat:"no-repeat"
          //         }}
        >
          <div className={styles.supplyflex}>
          <div className={styles.size}>
              <b>
                <NumberFormat
                  value={item.available}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                of{" "}
                {
                  <NumberFormat
                    value={item.supply}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </b>
            </div>
            {item.assetType.type == "dog" && (
                <div className={styles.gender}>{item.gender}<img
                  src={item.gender == "MALE" ? Male : Female}
                /></div>
            )}
            </div>
          <div className={styles.of}>
            <Tooltip title={item.rarity.name}>
              <img src={item.rarity.icon} />
            </Tooltip>
            <br />
            {/* {item.assetType.type == "dog" && (
              <Tooltip title={item.gender}>
                <img
                  src={item.gender == "MALE" ? Male : Female}
                  className={styles.gender}
                />
              </Tooltip>
            )} */}
          </div>
          {/* {item.attachments[0].fileType == "IMAGE" ? ( */}
          <img
            // srcSet={`${item.attachments[0].url} 2x`}
            // srcSet={`${Pug} 2x`}
            // src={Pug}
            src={item.previewImage}
            alt="Card"
          />
          {/* ) : (
              <img
              // srcSet={`${item.attachments[0].url} 2x`}
              // srcSet={`${Pug} 2x`}
              // src={Pug}
              src={item.attachments[1].url}
              alt="Card"
            />
            )}  */}

          <div className={styles.control}>
            <div
              className={cn(
                { "status-green": item.category === "green" },
                styles.category
              )}
            >
              {item.categoryText}
            </div>
            <button
              className={cn(styles.favorite, { [styles.active]: visible })}
              onClick={() => setVisible(!visible)}
            >
              <Icon name="heart" size="20" />
            </button>
            {/* <button className={cn("button-small", styles.button)}>
            <span>Place a bid</span>
            <Icon name="scatter-up" size="16" />
          </button> */}
          </div>
        </div>
        </div>
        <div className={styles.lineBorder}></div>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{item.name}</div>
            {/* <div className={styles.price}>{item.currentPrice}</div> */}
          </div>
          <div className={styles.counter}>
            {/* <div className={styles.size}>
              <b>
                <NumberFormat
                  value={item.available}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                of{" "}
                {
                  <NumberFormat
                    value={item.supply}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </b>
            </div> */}         
          </div>
          <div className={styles.bottom}>
            <div className={styles.stock}>
              <div className={styles.ethereum}>
                <img
                  className={
                    item.chainId == 1 ? styles.ethereumimg : styles.polygonimg
                  }
                  src={
                    item.chainId == 1
                      ? Ethereum
                      : item.chainId == 137
                      ? Polygon
                      : BonezImg
                  }
                />
                <div>
                  <b style={{fontSize:"12px"}}>
                    {" "}
                    {item.tokenPrice} {item.currency}
                  </b>
                  <p className={styles.tokenPrice}>
                    {" "}
                    <NumberFormat
                      value={(value * item.tokenPrice).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix="$ "
                    />{" "}
                    USD
                  </p>
                </div>
              </div>

              {item.saleType.type == "auction" ? (
                <button
                  className={
                    item.status != "COMING_SOON"
                      ? styles.bitNow
                      : styles.availablebutton
                  }
                >
                  {" "}
                  {item.status == "LIVE"
                    ? "BID NOW"
                    : item.status == "ENDED"
                    ? "BID ENDED"
                    : "COMING SOON"}
                </button>
              ) : (
                <div className={styles.buyNowDiv}>
                  <button
                    className={
                      item.status == "COMING_SOON"
                        ? styles.availablebutton  
                        : item.status == "ENDED" ? styles.bitNow
                        : styles.buyNow
                    }
                  >
                    {item.status == "LIVE"
                      ? item._id == "626f87b7d87315dcb8aec78e"
                        ? "MINT NOW"
                        : "BUY NOW"
                      : item.status == "ENDED"
                      ? "SOLD OUT"
                      : "COMING SOON"}
                  </button>
                  {/* <br /> */}
                  {/* {item.currentTierStock ? (
                    <p>{item.currentTierStock} left on this tier</p>
                  ) : null} */}
                </div>
              )}
            </div>
          </div>
          <div className={styles.line}>
            {/* <div className={styles.users}></div> */}
            {item.currentTierStock ? (
                <p>{item.currentTierStock} left on this tier</p>
              ) : null}
            {item.saleType.type == "auction" ? (
              <div className={styles.bidding}>
                {item.status == "LIVE" ? (
                  <>
                    Bidding Ends on
                    <br />
                    <CountDown asset={item.endDate} />{" "}
                  </>
                ) : null}
              </div>
            ) : item.tieredPricing ? (
              <div className={styles.tier}>
                <Tooltip
                  title={() => (
                    <div className={styles.tooltip}>
                      <Row>
                        <Col span={16}>
                          <div className={styles.head}>Lot</div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.head}>
                            <img
                              src={
                                item.chainId == 1
                                  ? Ethereum
                                  : item.chainId == 137
                                  ? Polygon
                                  : BonezImg
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      {(item.class == "Capsule"
                        ? capsule
                        : item.class == "Land"
                        ? land
                        : price
                      ).map((data, index) => {
                        return (
                          <div key={index} className={styles.rowdata}>
                            <Row>
                              <Col span={16}>
                                <div className={styles.pricefield}>
                                  {data.quantity}
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className={styles.pricefield}>
                                  {data.price}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  )}
                >
                  <i className="fa fa-fr fa-info-circle" />
                </Tooltip>
                <span>
                  Next Tier {item.nextTierPrice} {item.currency}
                </span>
                <GoArrowUp />
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className={styles.foot}>
          <div className={styles.status}>
            <Icon name="candlesticks-up" size="20" />
            Highest bid <span>{item.earnedCoins}</span>
          </div>
          <div
            className={styles.bid}
            // dangerouslySetInnerHTML={{ __html: item.bid }}
          />
        </div> */}
      </Link>
    </div>
  );
};

export default Card;
