import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import {
  getPosts,
  getSearch,
  getUser,
  getLandContract,
  getCapsuleContract,
  getUSD,
  getAssetContract,
} from "../../redux";
import { AiOutlineAlignLeft } from "react-icons/ai";
import Modal from "../NewModal";
import Stake from "./Stake";
import Bank from "../../screens/Bank";
import { Alert, Drawer } from "antd";
import Account from "./Account";
import DrawerContent from "./Drawer";
import { MdKeyboardArrowUp, MdOutlineClose } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import ProfileDrawerContent from "./ProfileDrawer";
import { useLocation } from "react-router-dom";
const nav = [
  {
    url: "/",
    title: "HOME",
    islogin: false,
  },
  {
    url: "/lottery",
    title: "LOTTERY",
    isLogin: false,
  },
  {
    url: "/marketplace",
    title: "MARKETPLACE",
    isLogin: false,
  },
  {
    url: "",
    title: "GAMES",
    isLogin: false,
    subHead: [
      // { title: "THE DOG TRACK", url: "/spaceship" },
      // { title: "RACE GAME", url: "/race" },
      { title: "SPACESHIP SHOOTER", url: "/spaceshooter" },
      // { title: "GEN ONE RACE", url: "/race" },
      // { title: "3D SPACE BATTLE", url: "/spaceshipGame" },
      // { title: "SPIN WHEEL", url: "/spin" },
    ],
  },
  {
    url: "",
    title: "BREEDING",
    isLogin: false,
    // logo: Lock,
    subHead: [
      { title: "BREEDING", url: "/breeding" },
      { title: "MASS BREEDING", url: "/breeding_g1" },
    ],
  },
];

const Headers = ({ history, account, active, connect, logout }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visibleNav, setVisibleNav] = useState(false);
  const [user, setUser] = useState({ id: "" });
  const [stick, setStick] = useState(false);
  const [pathNum, setPathNum] = useState(0);
  const [isStakeModal, setIsStakeModal] = useState(false);
  const [isBankModal, setIsBankModal] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [submenu, setSubmenu] = useState();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  let location = useLocation();
  if (state.Posts.isFirstLoaded === false) {
    dispatch(getPosts(state.Posts.filter));
  }
  useEffect(() => {
    dispatch(getSearch());
  }, []);
  useEffect(() => {
    if (location && !isBankModal && !isStakeModal && !visible) {
      let urlName = location.pathname.split("/");

      switch (urlName[1]) {
        case "":
          setPathNum(0);
          break;
        case "spaceship":
        case "spaceshooter":
        case "race":
        case "live-race":
        case "spaceshipGame":
        // case "spin":
          setPathNum(3);
          break;
        case "marketplace":
          setPathNum(2);
          break;
        case "lottery":
          setPathNum(1);
          break;
        case "breeding":
        case "breeding_g1":
          setPathNum(4);
          break;
        default:
          setPathNum(1000);
          break;
      }
    }
  });

  useEffect(() => {}, [state.Posts.posts]);
  // useEffect(() => {
  //   dispatch(getLandContract());
  // }, []);
  useEffect(() => {
    dispatch(getUSD());
  }, []);
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  const onCloseNotification = () => {
    setShowWarningBanner(false);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose1 = () => {
    setShow(false);
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisibleNav(false);
        setVisible(false);
      }}
    >
      <header className={stick ? styles.sticky : styles.header}>
        <div className={styles.container}>
          <div className={styles.menu} onClick={() => setOpen(true)}>
            <AiOutlineAlignLeft className={styles.icon} />
          </div>
          <div className={styles.heading}>
            <Link to={"/"}>
              <h3>CYBERDOGZ</h3>
            </Link>
          </div>
          <div className={styles.container1}>
            <div
              className={cn(styles.wrapper, { [styles.active]: visibleNav })}
            >
              {visibleNav && (
                <div
                  className={cn(styles.sideNav, {
                    [styles.active]: visibleNav,
                  })}
                >
                  <div className={styles.sideNavHead}></div>
                </div>
              )}

              <nav className={styles.nav}>
                {nav.map((x, index) =>
                  x.url ? (
                    <div key={index}>
                      {x.isLogin ? (
                        <Link
                          className={
                            pathNum == index ? styles.Selectedlink : styles.link
                          }
                          to={x.isLogin && x.url}
                          onClick={() => {
                            console.log(x);
                            if (x.isLogin && !account) {
                              setShowWarningBanner(true);
                            }
                            setVisibleNav(false);
                            setPathNum(index);
                          }}
                        >
                          {x.logo && <img src={x.logo} />}
                          {x.title}
                        </Link>
                      ) : (
                        <Link
                          className={
                            pathNum == index ? styles.Selectedlink : styles.link
                          }
                          to={x.url}
                          onClick={() => {
                            setVisibleNav(false);
                            setPathNum(index);
                          }}
                        >
                          {x.logo && <img src={x.logo} />}
                          {x.title}
                        </Link>
                      )}
                    </div>
                  ) : x.subHead && x.subHead.length ? (
                    <div className={styles.subhead_body} key={index}>
                      <button
                        className={
                          pathNum == index ? styles.Selectedlink : styles.link
                        }
                        key={index}
                        onClick={() => {
                          setVisible(!visible);
                          setSubmenu(x.subHead);
                          setPathNum(index);
                        }}
                      >
                        {x.logo && <img src={x.logo} />}
                        {x.title}
                        {index == pathNum && visible ? (
                          <MdKeyboardArrowUp className={styles.dropIcon} />
                        ) : (
                          <MdKeyboardArrowDown className={styles.dropIcon} />
                        )}
                      </button>
                      {visible && pathNum == index && (
                        <div className={styles.body}>
                          {submenu.map((x, index) => (
                            <div key={index}>
                              <Link
                                className={styles.link}
                                to={x.url}
                                onClick={() => {
                                  setVisible(false);
                                  setVisibleNav(false);
                                }}
                              >
                                {x.logo && <img src={x.logo} />}
                                {x.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      className={
                        pathNum == index ? styles.Selectedlink : styles.link
                      }
                      key={index}
                      onClick={() => {
                        if (x.isLogin && !account) {
                          setShowWarningBanner(true);
                        } else {
                          if (x.title == "STAKE") {
                            setIsStakeModal(true);
                          }
                          if (x.title == "BANK") {
                            setIsBankModal(true);
                          }
                          setVisibleNav(false);
                          setPathNum(index);
                        }
                      }}
                    >
                      {x.logo && <img src={x.logo} />}
                      {x.title}
                    </button>
                  )
                )}
              </nav>
            </div>
            {active && (
              <Account
                className="user"
                userDetail={user}
                stick={stick}
                account={account}
                active={active}
                connect={connect}
                logout={logout}
                showDrawer={() => setShow(true)}
              />
            )}

            <User
              className="user"
              userDetail={user}
              stick={stick}
              account={account}
              active={active}
              connect={connect}
              logout={logout}
            />

            {/* <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            >
              <img src={Menu} />
            </button> */}
          </div>
        </div>
        <Drawer
          // title={` Drawer`}
          placement="left"
          size={"default"}
          onClose={onClose}
          open={open}
          closable={true}
          contentWrapperStyle={{ backgroundColor: "#232323" }}
          closeIcon={<MdOutlineClose className={styles.closeIcon} />}
          drawerStyle={{ backgroundColor: "#232323" }}
          headerStyle={{
            backgroundColor: "#232323",
            marginLeft: "auto",
            borderBottom: "none",
          }}
          width={300}
          maskStyle={{ backdropFilter: "blur(7px)", opacity: 0.8 }}
        >
          <DrawerContent
            history={history}
            onClose={onClose}
            active={active}
            connect={connect}
            logout={logout}
          />
        </Drawer>
        <Drawer
          title={<div className={styles.drawerHeader}>Wallet</div>}
          placement="right"
          size={"default"}
          onClose={onClose1}
          open={show}
          closable={false}
          contentWrapperStyle={{ backgroundColor: "#232323" }}
          extra={
            <MdOutlineClose
              className={styles.closeIcon}
              onClick={() => onClose1()}
            />
          }
          drawerStyle={{ backgroundColor: "#232323" }}
          headerStyle={{
            backgroundColor: "#232323",
            justifyContent: "space-between",
            borderBottom: "1px solid #9698A1",
          }}
          width={300}
          maskStyle={{ backdropFilter: "blur(7px)", opacity: 0.8 }}
          bodyStyle={{ padding: "0px" }}
        >
          <ProfileDrawerContent
            history={history}
            onClose={onClose1}
            account={account}
            logout={logout}
          />
        </Drawer>
      </header>
      {showWarningBanner && !account && (
        <Alert
          message="Connect your Metamask wallet and try again"
          type="warning"
          closable
          onClose={onCloseNotification}
          style={{
            top: "72px",
            backgroundColor: "#ee9200",
            border: "none",
            fontWeight: 700,
            textAlign: "center",
          }}
        />
      )}
      <Modal
        visible={isStakeModal}
        onClose={() => setIsStakeModal(false)}
        close={styles.close}
        containerClassName={styles.modalContainer}
      >
        <Stake />
      </Modal>
      <Modal
        visible={isBankModal}
        onClose={() => setIsBankModal(false)}
        outerClassName={styles.bankmodal}
      >
        <Bank />
      </Modal>
    </OutsideClickHandler>
  );
};

export default Headers;
