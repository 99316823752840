import { Alert, Col, Row } from "antd";
import React, { useState } from "react";
import { minifyAddress } from "../../utils/helpers";
import styles from "./Lottery.module.sass";
import Shade from "../../images/stakeshade.png";
import { useNavigate } from "react-router-dom";
import LotteryLeaderBoard from "../../components/LotteryLeaderBoard";
import classNames from "classnames";
import Modal from "../../components/NewModal";
import LotteryRegister from "../../components/LotteryRegister";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import TextInput from "../../components/NewTextInput";
import { RegisterLottery } from "../../services/ApiServices";
import { Spinner } from "react-bootstrap";
import Send from "../../images/SendEmail.png";
import Coin from "../../images/CoinWallet.png";
import Win from "../../images/Win.png";
import BlackModal from "../../components/BlackModal";
import Icon from "../../components/Icon";
import { MdOutlineClose } from "react-icons/md";
import lotteryImg from "../../images/lottery.png";
const downloads = [
  {
    name: "Register your Email",
    text: "Here",
    content: "Confirm your email by clicking on link in your email.",
    icon: Send,
  },
  {
    name: "Connect your wallet",
    content: "Use MetaMask and connect to CYBERDOGZ.",
    icon: Coin,
  },

  {
    name: "Win CYBERDOGZ Gen One Rental",
    content:
      "Each day we give away doses on 24 hour rentals for you to participate in! Keep your earnings and split 80/20 with owners.",
    icon: Coin,
  },
];
const Lottery = (props) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [btnTxt, setBtnTxt] = useState("Register Now");
  const handleEmail = (e) => {
    let value = e.target.value;
    setEmailError("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length <= 0) {
      setEmailError("Please enter Email");
    } else if (!re.test(String(value).toLowerCase())) {
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(value);
    }
  };

  const register = async () => {
    if (emailError)
      return enqueueSnackbar("Email address is invalid", { variant: "error" });
    if (!email)
      return enqueueSnackbar("Email address is required", { variant: "error" });

    setLoading(true);
    let result;
    let body = {
      email: email,
    };
    try {
      result = await RegisterLottery(body);
    } catch (e) {
      setLoading(false);
      return enqueueSnackbar(e.message, { variant: "error" });
    }
    if (result.success) {
      setLoading(false);
      return enqueueSnackbar(result.message, { variant: "info" });
    }
  };
  const onCloseNotification = () => setShowWarningBanner(false);
  const registerResult = (data) => {
    setShowWarningBanner(true);
    setBtnTxt("Play Now");
    setResult(data);
    console.log(data);
  };
  return (
    <div className={classNames("container", styles.container)}>
      {showWarningBanner && (
        <Alert
          message="You have successfully registered your email address."
          type="warning"
          closable
          onClose={onCloseNotification}
          closeIcon={
            <MdOutlineClose style={{ fontSize: "20px", marginRight: "30px" }} />
          }
          style={{
            backgroundImage: "linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%)",
            border: "none",
            fontWeight: 700,
            textAlign: "center",
            padding: "20px 10px",
            marginBottom: "15px",
          }}
        />
      )}
      <div className={styles.lotteryBg}>
        <img src={lotteryImg} className={styles.lotteryMask} />
        <div className={styles.lotteryMask1}>
          <div className={styles.title}>CYBERDOGZ Lottery</div>
          <div className={styles.text}>
            To win a CYBERDOG for 24 hours register your email to Lottery.
            Winners will be announced everyday at 2PM UTC.
          </div>
          <button
            className={styles.registerBtn}
            onClick={() => {
              if (btnTxt === "Register Now") {
                setVisible(true);
              }
            }}
          >
            {btnTxt}
          </button>
        </div>
      </div>
      <div className={styles.heading}>Start Winning Today!</div>
      {/* <img src={Shade} className={styles.shade} /> */}
      <div className={styles.content}>
        <span>
          Join the CYBERDOGZ movement by following these 3 steps below. No
          purchase necessary.
        </span>
      </div>
      <div className={styles.container1}>
        <div className={styles.downloadFlex}>
          {downloads.map((x, index) => (
            <div key={index} className={styles.bg}>
              <img src={x.icon} />
              <h2>{x.name} </h2>
              <p>{x.content}</p>
            </div>
          ))}
        </div>

        {/* <div className={styles.email}>
        <TextInput
          type="email"
          onChange={handleEmail}
          placeholder="hello@example.com"
        />
      </div> */}

        {/* {loading ? (
        <button className={styles.registerButton}>
          Registering
          {loading && (
            <Spinner
              animation="border"
              variant="light"
              style={{ padding: "3%", marginLeft: "5px" }}
              size="sm"
            />
          )}
        </button>
      ) : (
        <button className={styles.registerButton} onClick={register}>
          Register Now
        </button>
      )} */}
        <LotteryLeaderBoard btnTxt={btnTxt} registerResult={registerResult} />
        <BlackModal
          visible={visible}
          onClose={() => setVisible(false)}
          close={styles.close}
          outerClassName={styles.modal}
        >
          <LotteryRegister
            onClose={() => setVisible(false)}
            registerResult={registerResult}
          />
        </BlackModal>
      </div>
    </div>
  );
};
export default Lottery;
