import React, { useCallback, useEffect, useState } from "react";
import styles from "./drawer.module.sass";
import { minifyAddress } from "../../../utils/helpers";
import { RiFileCopyLine, RiHandCoinLine } from "react-icons/ri";
import { Tooltip } from "antd";
import { useActiveWeb3React } from "../../../hooks";
import { toDisplay } from "../../../utils/helpers";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import Modal from "../../NewModal";
import Stake from "../Stake";
import { useSelector } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { RiBankLine } from "react-icons/ri";
import { BiLogOut, BiCoinStack } from "react-icons/bi";
import BlackModal from "../../BlackModal";
import { ethers, providers } from "ethers";
import { ALCHEMY_PROVIDER_KEY } from "../../../Config/config";
import ERC20 from "../../../abi/ERC20.json";

const ProfileDrawerContent = (props, { className }) => {
  const state = useSelector((state) => state);
  const [balance, setBalance] = useState(0);
  const [isStakeModal, setIsStakeModal] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { library, chainId } = useActiveWeb3React();
  const [user, setUser] = useState({});
  const [bonezBalance, setBonezBalance] = useState(0);

  const items = [
    {
      title: "My Profile",
      icon: <CgProfile />,
      url: "/profile",
    },
    {
      title: "My Referral",
      icon: <RiHandCoinLine />,
      url: "/referral",
    },
    {
      title: "Bank",
      icon: <RiBankLine />,
      url: "/bank",
    },
    {
      title: "Stake",
      icon: <BiCoinStack />,
      modal: true,
    },
    {
      title: "Logout",
      icon: <BiLogOut />,
    },
  ];
  const getBalance = useCallback(async () => {
    const balance = await library?.getBalance(account);
    if (typeof balance === "undefined") {
      return;
    }
    setBalance(toDisplay(balance));
  }, [account, library]);
  const CheckBalance = async () => {
    let balanceval = 0;
    try {
      const provider = new providers.AlchemyProvider(
        "matic",
        ALCHEMY_PROVIDER_KEY
      );
      const cyberdogzMarket = new ethers.Contract(
        "0xcF79651fBae99559127764a5ab808747fa3391ce",
        ERC20,
        provider
      );
      let ahtbalance = await cyberdogzMarket.balanceOf(account);
      balanceval = parseFloat(ethers.utils.formatEther(ahtbalance));
      setBonezBalance(balanceval);
      // console.log(
      //   "ahtbalance",
      //   parseFloat(ethers.utils.formatEther(ahtbalance))
      // );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account && chainId == 137) CheckBalance();
  }, [account, chainId]);
  useEffect(() => {
    if (active) {
      getBalance();
    }
  }, [active, getBalance, library]);

  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  return (
    <div>
      <div className={styles.content}>
        <span>
          {" "}
          {balance} {chainId == 137 ? "MATIC" : "ETH"}
        </span>
        <div>{chainId == 137 && <span> {bonezBalance} BONEZ</span>}</div>
        <p>{user.userName} </p>
        <div className={styles.address}>
          {account?.slice(0, 20) + "..."}
          <Tooltip title="Copied!" color="#414141" trigger="click">
            <RiFileCopyLine
              onClick={() => {
                navigator.clipboard.writeText(account);
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <div className={styles.body}>
          {items.map((x, index) => (
            <div key={index} className={styles.item}>
              {x.modal ? (
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    props.onClose();
                    setIsStakeModal(true);
                  }}
                >
                  {x.icon} {x.title}
                </div>
              ) : x.url ? (
                <Link
                  onClick={() => {
                    props.onClose();
                  }}
                  to={x.url}
                  className={styles.menuItem}
                >
                  {x.icon} {x.title}{" "}
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    props.onClose();
                    props.logout();
                  }}
                  // to={x.url}
                  className={styles.menuItem}
                >
                  {x.icon} {x.title}{" "}
                </Link>
              )}
            </div>
          ))}
          {/* <Link
            onClick={() => {
              props.onClose();
            }}
            className={styles.item}
            to={"/profile/" + account?.toString()}
          >
            <div className={styles.text}>My Profile</div>
          </Link>
          <Link
            onClick={() => {
              props.onClose();
            }}
            className={styles.item}
            to={"/bank"}
          >
            <div className={styles.text}>Bank</div>
          </Link>
          <button
            className={styles.item}
            onClick={() => {
              props.onClose();
              setIsStakeModal(true);
            }}
          >
            <div className={styles.text}>Stake</div>
          </button>
          <div
            className={styles.disconnect}
            onClick={() => {
              props.onClose();
              deactivate();
            }}
          >
            <CiLogout />
            Logout
          </div> */}
        </div>
      </div>
      <BlackModal
        visible={isStakeModal}
        onClose={() => setIsStakeModal(false)}
        close={styles.close}
        outerClassName={styles.modal}
        containerClassName={styles.modalContainer}
      >
        <Stake />
      </BlackModal>
    </div>
  );
};

export default ProfileDrawerContent;
