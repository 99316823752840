import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Faq from "./screens/Faq";
import Company from "./screens/Company";
import Cyberdogz from "./screens/Cyberdogz";
import MyAccount from "./screens/MyAccount";
import Marketplace from "./screens/Marketplace";
import Whitepaper from "./screens/Whitepaper";
import Racing from "./screens/Racing";
import NFTDrop from "./screens/NFTDrop";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import { SnackbarProvider } from "notistack";
import Terms from "./screens/Terms";
import Privacypolicy from "./screens/PrivacyPolicy";
import Disclaimer from "./screens/Disclaimer";
import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider as Web3Provider } from "@ethersproject/providers";
import "antd/dist/antd.css";
import ItemDetails from "./screens/ItemDetails";
import Bank from "./screens/Bank";
// import Game from "./screens/Game";
import Spaceship from "./screens/Spaceship";
import Account from "./screens/Account";
import Screen404 from "./screens/Screen404";
import BreedingG1 from "./screens/Breeding/BreedingG1";
import SpaceBattle from "./screens/SpaceBattle";
import Breeding from "./screens/Breeding/Breeding";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import Race from "./screens/Race";
import LiveRace from "./screens/LiveRace";
import SpaceshipGame from "./screens/SpaceshipGame";
import RaceTest from "./screens/Home/RaceTest";
import LotteryRegister from "./components/LotteryRegister";
import Lottery from "./screens/Lottery";
import VerifyEmail from "./screens/Verify";
import Referral from "./screens/Referral";
import Spin from "./screens/Spin";
function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
function App(props) {
  const reload = () => window.location.reload();

  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Page>
                    <Home />
                  </Page>
                }
              />
              <Route
                exact
                path="/home/:id/:code"
                element={<Home props={props} />}
              />
              <Route
                exact
                path="/spaceshooter"
                element={
                  <Page>
                    <SpaceBattle />
                  </Page>
                }
              />
              {/* <Route
          exact
          path="/battleGround"
          element={
            <Page>
              <Game />
            </Page>
          }
        /> */}
              {/* <Route
                exact
                path="/spaceship"
                element={
                  <Page>
                    <Spaceship />
                  </Page>
                }
              />
              
              <Route
                exact
                path="/race"
                element={
                  <Page>
                    <Race />
                  </Page>
                }
              />
              <Route
                exact
                path="/race/:status/:gameId"
                element={
                  <Page>
                    <LiveRace />
                  </Page>
                }
              />
              <Route
                exact
                path="/spaceshipGame"
                element={
                  <Page>
                    <SpaceshipGame />
                  </Page>
                }
              /> */}
              <Route
                exact
                path="/bank"
                element={
                  <Page>
                    <Bank />
                  </Page>
                }
              />
              <Route
                exact
                path="/Terms"
                element={
                  <Page>
                    <Terms />
                  </Page>
                }
              />
              <Route
                exact
                path="/Privacypolicy"
                element={
                  <Page>
                    <Privacypolicy />
                  </Page>
                }
              />
              <Route
                exact
                path="/Faq"
                element={
                  <Page>
                    <Faq />
                  </Page>
                }
              />
              <Route
                exact
                path="/Company"
                element={
                  <Page>
                    <Company />
                  </Page>
                }
              />
              <Route
                exact
                path="/Cyberdogz"
                element={
                  <Page>
                    <Cyberdogz />
                  </Page>
                }
              />
              <Route
                exact
                path="/Whitepaper"
                element={
                  <Page>
                    <Whitepaper />
                  </Page>
                }
              />
              <Route
                exact
                path="/MyAccount"
                element={
                  <Page>
                    <MyAccount />
                  </Page>
                }
              />

              <Route
                exact
                path="/Racing"
                element={
                  <Page>
                    <Racing />
                  </Page>
                }
              />
              <Route
                exact
                path="/NFTDrop"
                element={
                  <Page>
                    <NFTDrop />
                  </Page>
                }
              />
              <Route
                exact
                path="/Disclaimer"
                element={
                  <Page>
                    <Disclaimer />
                  </Page>
                }
              />
              <Route
                exact
                path="/marketplace"
                element={
                  <Page>
                    <Marketplace />
                  </Page>
                }
              />
              <Route
                exact
                path="/breeding_g1"
                element={
                  <Page>
                    <BreedingG1 />
                  </Page>
                }
              />

              <Route
                exact
                path="/breeding"
                element={
                  <Page>
                    <Breeding />
                  </Page>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <Page>
                    <Profile />
                  </Page>
                }
              />
              <Route
                exact
                path="/race/test"
                element={
                  <Page>
                    <RaceTest />
                  </Page>
                }
              />
              <Route
                exact
                path="/account/:uid"
                element={
                  <Page>
                    <Account />
                  </Page>
                }
              />
              <Route
                exact
                path="/detail/:id"
                element={
                  <Page>
                    <Item props={props} />
                  </Page>
                }
              />

              <Route path="/ads.txt" onEnter={reload} />
              <Route
                exact
                path="/lottery"
                element={
                  <Page>
                    <Lottery props={props} />
                  </Page>
                }
              />
              <Route
                exact
                path="/details/:id/:tokenId/:chainId"
                element={
                  <Page>
                    <ItemDetails props={props} />
                  </Page>
                }
              />
              <Route
                exact
                path="/verify/mail/:id"
                element={
                  <Page>
                    <VerifyEmail props={props} />
                  </Page>
                }
              />
              <Route
                exact
                path="/referral"
                element={
                  <Page>
                    <Referral props={props} />
                  </Page>
                }
              />
              <Route
                exact
                path="/invite/:id"
                element={
                  <Page>
                    <Referral props={props} />
                  </Page>
                }
              />
              {/* <Route
                exact
                path="/spin"
                element={
                  <Page>
                    <Spin />
                  </Page>
                }
              /> */}
              <Route
                exact
                path="/404"
                element={
                  <Page>
                    <Screen404 />
                  </Page>
                }
              />
              <Route
                path="*"
                exact={true}
                element={
                  <Page>
                    <Screen404 />
                  </Page>
                }
              />
            </Routes>
          </Router>
        </DndProvider>
      </Web3ReactProvider>
    </SnackbarProvider>
  );
}

export default App;
