import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import { userUpdate } from "../../services/ApiServices";
import "./index.css";
import ReactFlagsSelect from "react-flags-select";
import S3 from "aws-s3";
import aws from "../../services/AwsConfig";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import TextInput from "../../components/TransparentTextInput";
import { Col, Row } from "antd";
import { BiEditAlt } from "react-icons/bi";
import { HiOutlinePhotograph } from "react-icons/hi";
import BlackModal from "../../components/BlackModal";
import PrimaryButton from "../../components/PrimaryButton";
import { Spinner } from "react-bootstrap";
import { getUser } from "../../redux";
import { useDispatch } from "react-redux";
const options = ["Male", "Female"];

const ProfileEdit = ({ onEditModalClose, user, getUserFunction }) => {
  const { account, active, activate, deactivate } = useWeb3React();
  const [direction, setDirection] = useState(options[0]);
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setURL] = useState("");
  const [selected, setSelected] = useState("");
  const [loadingtext, setLoadingText] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isUpload, setIsupload] = useState(false);
  const [uploadingText, setUploadingText] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [imageError, setImageError] = useState("");
  const dispatch = useDispatch();

  const updateUser = async () => {
    setLoadingText("Updating");
    setIsLoading(true);
    let result;
    let body = {
      userName: name === user.userName ? "" : name,
      country: selected,
      profileUrl: url ? url : "",
    };
    try {
      result = await userUpdate(body, user.uid);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setFile("");
        setPreview("");
        setName("");
        setSelected("");
        onEditModalClose();
        getUserFunction();
        setIsLoading(false);
        dispatch(getUser(account));
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const initial = () => {
    setURL(user.profileUrl);
    setName(user.userName);
    setSelected(user.country);

    setFile();
    setError("");
    setPreview("");
    setImageError("");
  };
  useEffect(() => {
    initial();
  }, [user]);
  const handle = async (e) => {
    setImageError("");
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };
  const nameHandler = (e) => {
    setError("");
    if (e.target.value.length <= 2) {
      setError("Username should be minimum 3 characters");
    } else {
      setError("");
      setName(e.target.value);
    }
  };
  const AwsUpload = () => {
    if (!file) return setImageError("Select the image");

    setIsUploading(true);
    setUploadingText("Uploading");
    aws.dirName = aws.dirName + "/" + account;
    const fileName = moment.utc();
    const S3Client = new S3(aws);
    S3Client.uploadFile(file, fileName)
      .then((data) => {
        setURL(data.location);
        setIsupload(false);
        setIsUploading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const submit = () => {
    if (
      url == user.profileUrl &&
      name == user.userName &&
      selected == user.country
    )
      setError("No Changes to update");
    else updateUser();
  };
  const clear = () => {
    setFile();
    setName("");
    setError("");
    setSelected("");
    setPreview("");
  };

  return (
    <div className={cn("container", styles.container)}>
      <p className={styles.title}>Personal Settings</p>
      <Row>
        <Col xs={24} sm={24} md={13} lg={13}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.fieldset}>
                <TextInput
                  className={styles.field}
                  label="Name"
                  name="Name"
                  type="text"
                  defaultValue={name}
                  value={name}
                  placeholder="Enter your Name"
                  required
                  onChange={nameHandler}
                />
                <div className={styles.label}>Country</div>
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => {
                    setError("");
                    setSelected(code);
                  }}
                  className="flagsSelect"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} />
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              {url ? (
                <img src={url} alt="Avatar" />
              ) : (
                <div className={styles.template}>
                  <HiOutlinePhotograph className={styles.photo} />
                </div>
              )}
              <div className={styles.file}>
                <button className={styles.button}>
                  <BiEditAlt
                    className={styles.icon}
                    onClick={() => {
                      setIsupload(true);
                    }}
                  />
                </button>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.text}>
                Click to add image for easy identification
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {error && <div className={styles.error}>{error}</div>}

      <div className={styles.btns}>
        <button
          className={styles.yellowbutton}
          onClick={() => {
            submit();
          }}
        >
          <span className={styles.buttontext}>Save Changes</span>
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              style={{ marginLeft: "8px" }}
              size="sm"
              role="status"
              aria-hidden="true"
              variant="warning"
            />
          )}
        </button>
        <button className={styles.cancelButton} onClick={initial}>
          Cancel
        </button>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.member}>
        Member since {moment(user?.createdAt).format("MMM DD, YYYY")}
      </div>
      <div className={styles.divider}></div>
      <BlackModal
        visible={isUpload}
        onClose={() => {
          setIsupload(false);
        }}
        outerClassName={styles.modal}
      >
        <div>
          <div className={styles.heading}>Upload your profile image</div>
          <div>
            {preview ? (
              <img src={preview} className={styles.templateimg} />
            ) : (
              <div className={styles.templateimg}>
                <HiOutlinePhotograph className={styles.photo} />
              </div>
            )}
            <input
              className={styles.load}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handle}
              name={account + new Date().valueOf()}
            />
          </div>
          {imageError && <div className={styles.error}>{imageError}</div>}
          <PrimaryButton
            label="Upload"
            loadingtext={uploadingText}
            loading={isUploading}
            onClick={AwsUpload}
            className={styles.cancelbutton}
          />
        </div>
      </BlackModal>
    </div>
  );
};

export default ProfileEdit;
